exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-custom-landings-index-js": () => import("./../../../src/pages/customLandings/index.js" /* webpackChunkName: "component---src-pages-custom-landings-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-cookies-index-js": () => import("./../../../src/pages/legal/cookies/index.js" /* webpackChunkName: "component---src-pages-legal-cookies-index-js" */),
  "component---src-pages-legal-notice-index-js": () => import("./../../../src/pages/legal/notice/index.js" /* webpackChunkName: "component---src-pages-legal-notice-index-js" */),
  "component---src-pages-legal-privacy-index-js": () => import("./../../../src/pages/legal/privacy/index.js" /* webpackChunkName: "component---src-pages-legal-privacy-index-js" */),
  "component---src-pages-method-index-js": () => import("./../../../src/pages/method/index.js" /* webpackChunkName: "component---src-pages-method-index-js" */),
  "component---src-pages-thenext-venture-index-js": () => import("./../../../src/pages/thenextVenture/index.js" /* webpackChunkName: "component---src-pages-thenext-venture-index-js" */),
  "component---src-pages-weare-index-js": () => import("./../../../src/pages/weare/index.js" /* webpackChunkName: "component---src-pages-weare-index-js" */),
  "component---src-pages-works-compac-index-js": () => import("./../../../src/pages/works/Compac/index.js" /* webpackChunkName: "component---src-pages-works-compac-index-js" */),
  "component---src-pages-works-cotes-baixes-concept-index-js": () => import("./../../../src/pages/works/CotesBaixes/concept/index.js" /* webpackChunkName: "component---src-pages-works-cotes-baixes-concept-index-js" */),
  "component---src-pages-works-dexeus-index-js": () => import("./../../../src/pages/works/Dexeus/index.js" /* webpackChunkName: "component---src-pages-works-dexeus-index-js" */),
  "component---src-pages-works-g-ofit-index-js": () => import("./../../../src/pages/works/GOfit/index.js" /* webpackChunkName: "component---src-pages-works-g-ofit-index-js" */),
  "component---src-pages-works-identity-index-js": () => import("./../../../src/pages/works/identity/index.js" /* webpackChunkName: "component---src-pages-works-identity-index-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */),
  "component---src-pages-works-kulkana-index-js": () => import("./../../../src/pages/works/Kulkana/index.js" /* webpackChunkName: "component---src-pages-works-kulkana-index-js" */),
  "component---src-pages-works-l-escaleta-index-js": () => import("./../../../src/pages/works/LEscaleta/index.js" /* webpackChunkName: "component---src-pages-works-l-escaleta-index-js" */),
  "component---src-pages-works-la-bolera-campanar-index-js": () => import("./../../../src/pages/works/LaBoleraCampanar/index.js" /* webpackChunkName: "component---src-pages-works-la-bolera-campanar-index-js" */),
  "component---src-pages-works-origen-index-js": () => import("./../../../src/pages/works/Origen/index.js" /* webpackChunkName: "component---src-pages-works-origen-index-js" */),
  "component---src-pages-works-porcelanosa-index-js": () => import("./../../../src/pages/works/Porcelanosa/index.js" /* webpackChunkName: "component---src-pages-works-porcelanosa-index-js" */),
  "component---src-pages-works-queramic-concept-index-js": () => import("./../../../src/pages/works/Queramic/concept/index.js" /* webpackChunkName: "component---src-pages-works-queramic-concept-index-js" */),
  "component---src-pages-works-queramic-index-js": () => import("./../../../src/pages/works/Queramic/index.js" /* webpackChunkName: "component---src-pages-works-queramic-index-js" */),
  "component---src-pages-works-valentine-index-js": () => import("./../../../src/pages/works/Valentine/index.js" /* webpackChunkName: "component---src-pages-works-valentine-index-js" */),
  "component---src-pages-works-ventura-index-js": () => import("./../../../src/pages/works/Ventura/index.js" /* webpackChunkName: "component---src-pages-works-ventura-index-js" */),
  "component---src-templates-custom-landing-template-js": () => import("./../../../src/templates/customLandingTemplate.js" /* webpackChunkName: "component---src-templates-custom-landing-template-js" */)
}

